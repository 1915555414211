.stars {
  --star-size: 21px;
  --star-color: var(--color-grey-400);
  --star-background: var(--color-orange-500);
  --percent: calc(var(--rating) / var(--max-rating) * 100%);

  display: block;
  font-family: Times, serif;
  font-size: var(--star-size);
  height: var(--star-size);

  letter-spacing: -1px;
  line-height: 1;
  position: relative;

  --before-content: '★★★★★';
}

.stars::before {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
  -webkit-background-clip: text;
  content: var(--before-content);
}

.stars_single {
  --before-content: '★';
}
